import { applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable'
import { createBrowserHistory } from 'history'
import createRootReducer from '../reducers'
import rootEpic from '../epics'
import thunk from 'redux-thunk'
import LogRocket from 'logrocket'

const epicMiddleware = createEpicMiddleware()
export const history = createBrowserHistory()

const middlewares = [routerMiddleware(history), thunk, epicMiddleware]
/*
if (process.env.REACT_APP_GAMESHOW_REDUX_LOGGING) {
	LogRocket.log('Using Redux-Logger')
	const { createLogger } = require('redux-logger')
	const logger = createLogger({
		collapsed: true
	})
	middlewares.push(logger)
}
*/
LogRocket.log('Using LogRocket Middleware.')
middlewares.push(LogRocket.reduxMiddleware())

const appReducer = createRootReducer(history)
const rootReducer = (state, action) => {
	if (action.type === 'APP_RESET') {
		const { user } = state
		state = { user }
	}
	return appReducer(state, action)
}

export default function configureStore() {
	const store = createStore(
		rootReducer,
		compose(applyMiddleware(...middlewares))
	)
	epicMiddleware.run(rootEpic)

	return store
}
